import { Injectable, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash';
import { HttpService } from './http.service';
import { Subscription, SubscriptionPaginatedResponse } from '../models/subscription.model';
import { FC_ENV, Environment } from '../models';

@Injectable()
export class SubscriptionService {
    public omit: Array<string> = ['created_at', 'updated_at', 'name'];
    public endpoint: string = 'subscriptions';

    constructor(
        protected http: HttpService,
        @Inject(FC_ENV) protected environment: Environment,
    ) { }

    public ngOnInit(): void {

    }

    /**
     * Request all Subscriptions.
     */
    public all(page: number = 1, params: string = ''): Observable<SubscriptionPaginatedResponse> {
        return this.http
            .hostname(this.environment.api)
            .get(`${this.endpoint}?page=${page}&${params}`);
    }

    /**
     * Request the user's Subscriptions.
     */
    public mine(): Observable<SubscriptionPaginatedResponse> {
        return this.http
            .hostname(this.environment.api)
            .get('subscriptions?whereNull=campaign_id');
    }

    /**
     * Request a given Subscription.
     */
    public one(id: number, params: string = ''): Observable<Subscription> {
        return this.http
            .hostname(this.environment.api)
            .get(`subscriptions/${id}?${params}`);
    }

    /**
     * Store the given Subscription.
     * Figures out if we should POST or PATCH whether there is an ID.
     */
    public store(Subscription: Subscription): Observable<Subscription> {
        if (Subscription.id > 0) {
            return this.patch(_.omit(Subscription, this.omit));
        }

        return this.post(Subscription);
    }

    /**
     * Create a Subscription.
     */
    public post(Subscription?: any): Observable<any> {
        return this.http
            .hostname(this.environment.api)
            .post('subscriptions', Subscription);
    }

    /**
     * Update a Subscription.
     */
    public patch(Subscription: any): Observable<any> {
        return this.http
            .hostname(this.environment.api)
            .patch(`subscriptions/${Subscription.id}`, Subscription);
    }

    /**
     * Delete a Subscription.
     */
    public delete(Subscription: Subscription): Observable<null> {
        return this.http
            .hostname(this.environment.api)
            .delete(`subscriptions/${Subscription.id}`);
    }
}
