import { Injectable, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash';
import { HttpService } from './http.service';
import { Campaign, CampaignPaginatedResponse } from '../models/campaign.model';
import { FC_ENV, Environment } from '../models';
import { TokenService } from '../services/token.service';
import { User } from '../models/user.model';

@Injectable()
export class CampaignService {
    public omit: Array<string> = ['created_at', 'updated_at', 'name'];
    public endpoint: string = 'campaigns';

    constructor(
        protected http: HttpService,
        @Inject(FC_ENV) protected environment: Environment,
        protected tokenService: TokenService,
        protected user: User
    ) { }

    public ngOnInit(): void {
        this.user = this.tokenService.getUser();
        if (this.user) {
            this.endpoint = 'campaign';
        }
    }

    /**
     * Request all campaigns.
     */
    public all(page: number, relationships: string = '', where: string = ''): Observable<CampaignPaginatedResponse> {
        return this.http
            .hostname(this.environment.api)
            .get(`${this.endpoint}?page=${page}&with=${relationships}&where=${where}`);
    }

    /**
     * Request active campaigns for home page.
     */
    public home(): Observable<CampaignPaginatedResponse> {
        return this.http
            .hostname(this.environment.api)
            .get(`${this.endpoint}?with=image&where=live~1&orderby=created_at&orderby=monetary_total&direction=desc&limit=8`);
    }

    /**
     * Request active campaigns.
     */
    public active(): Observable<CampaignPaginatedResponse> {
        return this.http
            .hostname(this.environment.api)
            .get(`${this.endpoint}?with=image&where=live~1&orderby=created_at&orderby=monetary_total&direction=desc`);
    }

    /**
     * Request the user's campaigns.
     */
    public mine(): Observable<CampaignPaginatedResponse> {
        return this.http
            .hostname(this.environment.api)
            .get('campaign?with=image&where=user_id~me');
    }

    /**
     * Request a given campaign.
     */
    public one(id: number, relationships?: string): Observable<Campaign> {
        if (!relationships) {
            relationships = '';
        }
        return this.http
            .hostname(this.environment.api)
            .get(`campaigns/${id}?with=${relationships}`);
    }

    /**
     * Store the given campaign.
     * Figures out if we should POST or PATCH whether there is an ID.
     */
    public store(campaign: any): Observable<Campaign> {
        if (campaign.id > 0) {
            return this.patch(_.omit(campaign, this.omit));
        }

        return this.post(campaign);
    }

    /**
     * Create a campaign.
     */
    public post(campaign: any): Observable<any> {
        return this.http
            .hostname(this.environment.api)
            .post('campaigns', campaign);
    }

    /**
     * Update a campaign.
     */
    public patch(campaign: any): Observable<any> {
        console.log('line 63, campaign = ', campaign);
        return this.http
            .hostname(this.environment.api)
            .patch(`campaigns/${campaign.id}`, campaign);
    }

    /**
     * Delete a campaign.
     */
    public delete(campaign: Campaign): Observable<null> {
        return this.http
            .hostname(this.environment.api)
            .delete(`campaigns/${campaign.id}`);
    }

    /**
     * Transfer funds
     */
    public transferFunds(params: any): Observable<null> {
        return this.http
            .hostname(this.environment.api)
            .post(`stripe/transfer-to-client`, params);
    }
}
