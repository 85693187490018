import { Injectable, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash';
import { HttpService } from './http.service';
import { Email, EmailPaginatedResponse } from '../models/email.model';
import { FC_ENV, Environment } from '../models';

@Injectable()
export class EmailService {
    public omit: Array<string> = ['active', 'created_at', 'updated_at', 'name'];
    public endpoint: string = 'emails';

    constructor(
        protected http: HttpService,
        @Inject(FC_ENV) protected environment: Environment,
    ) { }

    public ngOnInit(): void {

    }

    /**
     * Request all emails.
     */
    public all(page: number = 1, params: string = ''): Observable<EmailPaginatedResponse> {
        return this.http
            .hostname(this.environment.api)
            .get(`${this.endpoint}?page=${page}&${params}`);
    }

    /**
     * Request the user's emails.
     */
    public mine(): Observable<EmailPaginatedResponse> {
        return this.http
            .hostname(this.environment.api)
            .get('email?with=image&where=user_id~me');
    }

    /**
     * Request a given email.
     */
    public one(id: number, params: string = ''): Observable<Email> {
        return this.http
            .hostname(this.environment.api)
            .get(`emails/${id}?${params}`);
    }

    /**
     * Store the given email.
     * Figures out if we should POST or PATCH whether there is an ID.
     */
    public store(email: Email): Observable<Email> {
        if (email.id > 0) {
            return this.patch(_.omit(email, this.omit));
        }

        return this.post(email);
    }

    /**
     * Create a email.
     */
    public post(email?: any): Observable<any> {
        return this.http
            .hostname(this.environment.api)
            .post('emails', email);
    }

    /**
     * Update a email.
     */
    public patch(email: any): Observable<any> {
        return this.http
            .hostname(this.environment.api)
            .patch(`emails/${email.id}`, email);
    }

    /**
     * Delete a email.
     */
    public delete(email: Email): Observable<null> {
        return this.http
            .hostname(this.environment.api)
            .delete(`emails/${email.id}`);
    }

    /**
     * Transfer funds
     */
    public transferFunds(params: any): Observable<null> {
        return this.http
            .hostname(this.environment.api)
            .post(`stripe/transfer-to-client`, params);
    }
}
