import { Injectable, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash';

import { HttpService } from './http.service';
import { User, UserPaginatedResponse } from '../models/user.model';

import { FC_ENV, Environment } from '../models';
import { TokenService } from './token.service';


@Injectable()
export class UserService implements OnInit {
    public omit: Array<string> = ['created_at', 'updated_at', 'name'];

    constructor (
        protected http: HttpService,
        @Inject(FC_ENV) protected environment: Environment
    ) {}

    ngOnInit() {
        console.log('ngOnInit fired');
    }

    /**
     * Request the users.
     */
    public all(): Observable<UserPaginatedResponse> {
        return this.http
            .hostname(this.environment.api)
            .get('users');
    }

    /**
     * Request a given user.
     */
    public one(id: number, relationships?: string): Observable<User> {
        if (!relationships) {
            relationships = '';
        }
        return this.http
            .hostname(this.environment.api)
            .get(`users/${id}?with=${relationships}`);
    }

    /**
     * Request a the current user.
     */
    public me(relationships?: string): Observable<User> {
        if (!relationships) {
            relationships = '';
        }
        return this.http
            .hostname(this.environment.api)
            .get(`users/me?with=${relationships}`);
    }

    /**
     * Store the given user.
     * Figures out if we should POST or PATCH whether there is an ID.
     */
    public store(user: User): Observable<User> {
        if (user.id > 0) {
            return this.patch(_.omit(user, this.omit));
        }

        return this.post(user);
    }

    /**
     * Create a user (for admins).
     */
    public post(user: User): Observable<User> {
        return this.http
            .hostname(this.environment.api)
            .post('users', user);
    }

    /**
     * Register a user (for new users).
     */
    public register(user: User): Observable<null> {
        var response = this.http
            .hostname(this.environment.api)
            .post('register', user);
        return response;
    }

    /**
     * Update a user.
     */
    public patch(user: User): Observable<User> {
        return this.http
            .hostname(this.environment.api)
            .patch(`users/${user.id}`, user);
    }

    /**
     * Delete a user.
     */
    public delete(user: User): Observable<null> {
        return this.http
            .hostname(this.environment.api)
            .delete(`users/${user.id}`);
    }
}
